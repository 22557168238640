<template>
  <div class="clothes-main">
    <div>
      <h2 class="title">衣服信息</h2>
      <van-form @submit="onSubmit">
        <van-cell-group inset>
          <van-field label="洗衣编号" name="clothesNo" :model-value="details.clothesNo" readonly />
          <van-field label="姓名" name="username" :model-value="details.username" readonly />
          <van-field
            v-model="remark"
            name="remark"
            rows="2"
            autosize
            label="备注"
            type="textarea"
            maxlength="100"
            placeholder="请输入备注信息"
            show-word-limit
          />
          <van-field name="photo" label="照片上传">
            <template #input>
              <van-uploader
                v-model="fileList"
                multiple
                :before-read="beforeRead"
                :after-read="afterRead"
                :before-delete="beforeDelete"
                :max-count="20"
              />
            </template>
          </van-field>
        </van-cell-group>
        <div style="margin: 16px;">
          <van-button round block type="primary" native-type="submit">
            提交
          </van-button>
        </div>
      </van-form>

    </div>
    <WxNoShare />
  </div>
</template>

<script>
  import { ref, onMounted, getCurrentInstance } from 'vue';
  import { useRouter } from 'vue-router';
  import axios from 'axios';
  import { Grid, GridItem, Popup, Form, Field, Cell, CellGroup, Uploader, Toast } from 'vant';
  import Compressor from 'compressorjs';

  export default {
    components: {
      [Grid.name]: Grid,
      [GridItem.name]: GridItem,
      [Popup.name]: Popup,
      [Form.name]: Form,
      [Field.name]: Field,
      [Uploader.name]: Uploader,
      [Cell.name]: Cell,
      [CellGroup.name]: CellGroup,
      [Toast.name]: Toast,
    },
    setup() {
      const { proxy } = getCurrentInstance();
      const details = ref({});
      const fileList = ref([]);
      const thumbnail = ref([]);
      const remark = ref('');
      const router = useRouter();
      const rtype = router.currentRoute.value.query.type;
      const clothesNo = router.currentRoute.value.query.no;

      onMounted(async () => {
        proxy.$api.get('laundrysStans/show', { no: clothesNo, type: rtype }, res=>{
          details.value = res.result;
          remark.value = details.value.remark;
          details.value.username = rtype == 1 ? res.result.username : res.result.laundry.username;
          if (details.value.thumbnail && details.value.thumbnail.length > 0) {
            fileList.value = details.value.thumbnail.map((item) => {
              thumbnail.value.push(item);
              return { url: item + '?x-oss-process=image/resize,l_500' };
            });
          }
        })
      });

      //图片压缩
      function ImageCompressor(file, backType, quality) {
        return new Promise((resolve, reject) => {
          new Compressor(file, {
            quality: quality || 0.6, //压缩质量
            success(result) {
              if (!backType || backType == 'blob') {
                resolve(result);
              } else if (backType == 'file') {
                resolve(file);
              } else {
                resolve(file);
              }
              // resolve(result);
            },
            error(err) {
              console.log('图片压缩失败');
              reject(err);
            },
          });
        });
      }

      const beforeRead = (file) =>
        // eslint-disable-next-line no-async-promise-executor
        new Promise(async (resolve) => {
          if (file.type == 'image/jpeg' || file.type == 'image/png') {
            // compressorjs 默认开启 checkOrientation 选项
            // 会将图片修正为正确方向
            new Compressor(file, {
              success: resolve,
              error(err) {
                console.log(err.message);
              },
            });
            await ImageCompressor(file, 'file', 0.6); //图片压缩
          } else {
            Toast('请上传 jpg, png 格式的图片');
            return false;
          }
        });

      const afterRead = async (file) => {
        file.status = 'uploading';
        file.message = '上传中...';
        let formData = new FormData();
        formData.append('file', new Blob([file.file])); //转换成二进制流
        axios.post(process.env.VUE_APP_API_URL + 'upload', formData).then(function (res) {
          let data = res.data
          file.status = 'done';
          file.message = '上传完成';
          thumbnail.value.push(data.result.url);
        }).catch(function (error) {
          file.status = 'failed';
          file.message = '上传失败';
          console.log(error);
        });
      };

      const beforeDelete = (file) => {
        let newThumbnail = [];
        thumbnail.value.map((item) => {
          if (item != file.url) {
            newThumbnail.push(item);
          }
        });
        thumbnail.value = newThumbnail;
        return true;
      };

      function onSubmit(values) {
        try {
          delete values.photo;
          if (!values.remark && thumbnail.value.length == 0) {
            Toast('备注信息、照片必须输入上传一个才可提交！');
            return;
          }
          if (thumbnail.value.length > 0 && fileList.value.length > 0) {
            values.thumbnail = thumbnail.value;
          }
          values.clothesNo = details.value.clothesNo;
          values.id = details.value.id;
          let url = rtype == 1 ? 'laundrys/updateOther' : 'laundrysStans/updateOther';
          proxy.$api.post(url, values, res=>{
            Toast(res.result.msg);
          }) 
        // eslint-disable-next-line no-empty
        } finally {}
      }

      return {
        details,
        remark,
        fileList,
        onSubmit,
        beforeRead,
        afterRead,
        beforeDelete,
      };
    },
  };
</script>
<style scoped>
  .clothes-main {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: #fff;
  }
  .title {
    font-weight: bold;
    padding: 10px 15px;
    margin: 0;
    border-bottom: 1px solid #f0f0f0;
  }
</style>
