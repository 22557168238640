<template>
  <div>
    <div class="floating">
      <router-link to="/" exact >
        <span>
          <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-zhuye"></use>
        </svg>
        </span>
      </router-link>
      <router-link :to="url" v-if="url != ''">
        <span>
          <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-return"></use>
        </svg>
        </span>
      </router-link>
      <router-link to v-else >
        <span @click="$router.back(-1)">
          <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-return"></use>
        </svg>
        </span>
      </router-link>
    </div>

  </div>
</template>

<script>
    export default {
      name: 'floating',
      props: {
        'skipUrl': String
      },
      data() {
        return {
          url: ''
        }
      },
      created() {
        if(this.skipUrl){
          this.url = this.skipUrl
        }
      }
    }
</script>

<style scoped></style>
