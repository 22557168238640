<template>
  <div class="main">
    <div class="u-info-top">
      <van-image
        round
        width="80px"
        height="80px"
        src="https://thirdwx.qlogo.cn/mmopen/vi_32/CNUnt4ptMzd1lakZbB2BFkhrDlDb3jHY1GK3clKjibib9XX0icGatNEMOEUPonkhjtaZMIehcbf2XVskAiahppJ4jw/132"
      />
      <h3>俊杰</h3>
    </div>
    <van-form @submit="onSubmit" class="c-e-form">
      <van-field
        v-model="name"
        name="name"
        label="姓名"
        placeholder="请填写姓名"
        :rules="[{ required: true, message: '请填写姓名' }]"
      />
      <van-field
        v-model="hotline"
        name="phone"
        label="手机号"
        placeholder="请填写手机号"
        :rules="[{ required: true, message: '请填写手机号' }]"
      />
      <van-field
        v-model="sms"
        center
        clearable
        label="验证码"
        placeholder="请填写验证码"
        :rules="[{ required: true, message: '请填写验证码' }]"
      >
        <template #button>
          <van-button size="small" type="primary">发送验证码</van-button>
        </template>
      </van-field>
      <van-field name="uploader" label="营业执照">
        <template #input>
          <van-uploader v-model="value" />
        </template>
      </van-field>
      <van-field
        v-model="name"
        name="name"
        label="门店名称"
        placeholder="请填写门店名称"
        :rules="[{ required: true, message: '请填写门店名称' }]"
      />
      <div class="sub-btn">
        <van-button round block type="primary" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
  import { ref } from 'vue';
  import { Form, Field, Cell, CellGroup, RadioGroup, Radio, Image as VanImage, Uploader } from 'vant';

  export default {
    components: {
      [Form.name]: Form,
      [Field.name]: Field,
      [Cell.name]: Cell,
      [CellGroup.name]: CellGroup,
      [RadioGroup.name]: RadioGroup,
      [Radio.name]: Radio,
      [Uploader.name]: Uploader,
      [VanImage.name]: VanImage,
    },
    setup() {
      const a = ref('');
 
      console.log('weixin', a);

      function onSubmit (values) {
        this.$api.post('stores/update', values, r=>{
          if(r.status === 200) {
            this.$toast('保存成功')
          }
        })
      }

      return { onSubmit };
    },
  };
</script>

<style scoped>
  .main {
    background-color: #fff;
    width: 100%;
    height: 100%;
    position: fixed;
    overflow: hidden;
  }
  .u-info-top {
    text-align: center;
    background-color: #fff;
    padding-top: 15px;
  }
  .u-info-top h3 {
    padding: 10px 0;
    margin: 0;
  }
  .sub-btn {
    margin: 15px;
  }
</style>
