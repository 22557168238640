import { Toast } from 'vant';
import store from '../store'

// 引用axios
const axios = require('axios')
// 自定义判断元素类型JS
function toType (obj) {
  return ({}).toString.call(obj).match(/\s([a-zA-Z]+)/)[1].toLowerCase()
}
// 参数过滤函数
function filterNull (o) {
  for (var key in o) {
    if (o[key] === null) {
      delete o[key]
    }
    if (toType(o[key]) === 'string') {
      o[key] = o[key].trim()
    } else if (toType(o[key]) === 'object') {
      o[key] = filterNull(o[key])
    } else if (toType(o[key]) === 'array') {
      o[key] = filterNull(o[key])
    }
  }
  return o
}

/*
 接口处理函数
*/
function apiAxios (method, url, params, success, failure) {
  if (params) {
    params = filterNull(params)
  }
  axios({
    method: method,
    url: url,
    data: method === 'POST' || method === 'PUT' ? params : null,
    params: method === 'GET' || method === 'DELETE' ? params : null,
    baseURL: process.env.VUE_APP_ADMIN_API_URL,
    withCredentials: false,
    headers: {
      'Authorization': 'Bearer ' + sessionStorage.token
    }
  })
  .then(function (res) {
    if(typeof res == 'undefined') {
      // 请求失败
      window.location.reload()
      return
    }
    if (res.data.code === 200) {
      if (success) {
        success(res.data)
      }
    } else {
      if (failure) {
        failure(res.data)
      } else {
        //window.alert('error: ' + JSON.stringify(res.data))
        Toast(res.data.message)
        return
      }
    }
  })
  .catch(function (err) {
    if (err) {
      let res = err.response;
      if(res.data.message === 'Unauthenticated.') {
        // 请求失败
        store.dispatch('logout');
        //window.location.reload()
        return
      }
      if(res.status == 403 || res.status == 401) {
        Toast(err.response.data.message);
        store.dispatch('logout');
        return
      }
      //window.alert('api error, HTTP CODE: ' + res.status)
      let errors = res.data.errors
      for (let key in errors) {
        Toast(errors[key][0])
        return
      }
      Toast(res.data.message)
    }
  })
}

// 返回在vue模板中的调用接口
export default {
  get: function (url, params, success, failure) {
    return apiAxios('GET', url, params, success, failure)
  },
  post: function (url, params, success, failure) {
    return apiAxios('POST', url, params, success, failure)
  },
  put: function (url, params, success, failure) {
    return apiAxios('PUT', url, params, success, failure)
  },
  delete: function (url, params, success, failure) {
    return apiAxios('DELETE', url, params, success, failure)
  }
}

