import { createRouter, createWebHistory } from 'vue-router'

import Empty from '../views/empty/index.vue'
import Login from '../views/login/index.vue'
import Wechat from '../views/login/wechat.vue'
import Activity from '../views/activity/index.vue'
import ActivityDetails from '../views/activity/details.vue'
import BindingWeixin from '../views/binding/weixin.vue'
import PaymentIndex from '../views/payment/index.vue'
import PaymentSucceed from '../views/payment/succeed.vue'
import Manage from '../views/manage/index.vue'
import Shop from '../views/shop/index.vue'
import User from '../views/user/index.vue'
import OrderList from '../views/users/order_list/index.vue'
import OrderDetails from '../views/users/order_details/index.vue'
import UsersSetting from '../views/users/setting/index.vue'
import UsersEdit from '../views/users/setting/edit.vue'
import UsersSecurity from '../views/users/setting/security.vue'
import UsersProfit from '../views/users/profit/index.vue'
import LaundrysClothes from '../views/laundrys/clothes.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: '工作台',
      show: true,
      requireAuth: true,
    },
    component: Manage
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录',
      show: true,
      requireAuth: false,
    },
    component: Login
  },
  {
    path: '/login/wechat',
    name: 'loginWechat',
    meta: {
      title: '微信登录',
      show: true,
      requireAuth: false,
    },
    component: Wechat
  },
  {
    path: '/binding/weixin',
    name: 'bindingWeixin',
    meta: {
      title: '绑定微信',
      show: true,
      requireAuth: true,
    },
    component: BindingWeixin
  },
  {
    path: '/manage/index',
    name: 'manage',
    meta: {
      title: '工作台',
      show: true,
      requireAuth: true,
    },
    component: Manage
  },
  {
    path: '/shop/index',
    name: 'shop',
    meta: {
      title: '门店',
      show: true,
      requireAuth: true,
    },
    component: Shop
  },
  {
    path: '/laundrys/clothes',
    name: 'laundrysClothes',
    meta: {
      title: '门店',
      show: true,
      requireAuth: false,
    },
    component: LaundrysClothes
  },
  {
    path: '/activity/index',
    name: 'activity',
    meta: {
      title: '活动',
      show: true,
      requireAuth: true,
    },
    component: Activity
  },
  {
    path: '/activity/details',
    name: 'activityDetails',
    meta: {
      title: '活动详情',
      show: true,
      requireAuth: true,
    },
    component: ActivityDetails
  },
  {
    path: '/payment/index',
    name: 'paymentIndex',
    meta: {
      title: '支付',
      show: true,
      requireAuth: true,
    },
    component: PaymentIndex
  },
  {
    path: '/payment/succeed',
    name: 'paymentSucceed',
    meta: {
      title: '支付成功',
      show: true,
      requireAuth: true,
    },
    component: PaymentSucceed
  },
  {
    path: '/user/index',
    name: 'user',
    meta: {
      title: '我的',
      show: true,
      requireAuth: true,
    },
    component: User
  },
  {
    path: '/users',
    name: 'UserIndex',
    component: User,
    redirect: '/user/index',
    meta: {
      title: '我的',
      show: true,
      requireAuth: false,
      isAdmin: false
    },
    children: [
      {
        path: 'order',
        name: 'OrderList',
        component: OrderList,
        meta: {
          title: '全部订单',
          show: true,
          requireAuth: true,
        },
      },
      {
        path: 'order_details',
        name: 'OrderDetails',
        component: OrderDetails,
        meta: {
          title: '订单详情',
          show: true,
          requireAuth: true,
        },
      },
      {
        path: 'profit',
        name: 'UsersProfit',
        component: UsersProfit,
        meta: {
          title: '我的收益',
          show: true,
          requireAuth: true,
        },
      },
      {
        path: 'setting',
        name: 'UsersSetting',
        component: UsersSetting,
        meta: {
          title: '设置',
          show: true,
          requireAuth: true,
        },
      },
      {
        path: 'edit',
        name: 'UsersEdit',
        component: UsersEdit,
        meta: {
          title: '个人资料',
          show: true,
          requireAuth: true,
        },
      },
      {
        path: 'security',
        name: 'UsersSecurity',
        component: UsersSecurity,
        meta: {
          title: '账户安全',
          show: true,
          requireAuth: true,
        },
      },
    ]
  },
  {
    path: '/empty',
    name: 'Empty',
    component: Empty,
    meta: {
      title: '空页面',
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router
