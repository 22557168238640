<template>
  <div>
    <van-empty description="暂无数据" />
    <WxNoShare />
  </div>
</template>

<script>
  import { Empty } from 'vant';

  export default {
    components: {
      [Empty.name]: Empty,
    },
    name: 'Empty',
    data () {
      return {
        user: {}
      }
    },
    created () {

    },
    methods: {

    }
  }
</script>
<style scoped>

</style>
